import { useState } from 'react';

const defaultValue = { page: 1, size: 50 };

function usePagination() {
  const [pagination, setPagination] = useState(defaultValue);

  const changePage = (page) => {
    setPagination({ ...pagination, page: page + 1 });
  };

  const changePageSize = (size) => {
    setPagination({ size, page: 1 });
  };

  return {
    pagination,
    changePage,
    changePageSize,
  };
}

export default usePagination;
