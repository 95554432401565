import React, { useCallback, useId, useState } from 'react';
import CloseIcon from 'Assets/svg/close-material.svg';
import OpenSearchIcon from 'Assets/svg/open-search.svg';
import { EMPTY_FUNCTION } from 'Utils/constants';
import InlineLoader from '../InlineLoader';
import ControlInput from '../ControlInput';
import useOutsideClick from '../useOutsideClick';
import './styles.scss';

/**
 *
 * @param {*} offsetValues is used for providing space at top/bottom of screen or
 * constraint within a window
 * @param {*} options.elementOffset to provide space around element and suggestion/dropdown box
 */
export function getSuggestionStyle(element, offsetValues, options = {}) {
  const {
    considerHeight = true,
    fixedPosition = false,
    elementOffset = {},
  } = options;
  const style = {};
  const { top: elementTopSpace = 0, bottom: elementBottomSpace = 0 } =
    elementOffset;
  const { top: offsetTop = 5, bottom: offsetBottom = 5 } = offsetValues || {};
  const {
    top,
    bottom: bottomFromTop,
    left,
    right,
    height,
  } = element.getBoundingClientRect();
  const windowHeight = window.innerHeight;
  const availableBottomSpace = windowHeight - bottomFromTop - offsetBottom;
  const availableTopSpace = top - offsetTop;

  if (fixedPosition) {
    const availableRightSpace = window.innerWidth - right;
    if (availableRightSpace < 200) {
      style.right = `${availableRightSpace}px`;
      style.left = 'auto';
    } else {
      style.left = `${left}px`;
    }
    if (availableBottomSpace < 300 && availableTopSpace > 300) {
      style.top = 'auto';
      style.bottom = `${availableBottomSpace + offsetBottom + elementTopSpace + (considerHeight ? height : 0)}px`;
      style.maxHeight = `${availableTopSpace - elementTopSpace}px`;
    } else {
      style.top = `${top + elementBottomSpace + (considerHeight ? height : 0)}px`;
      style.maxHeight = `${availableBottomSpace - elementBottomSpace}px`;
    }
  } else if (availableBottomSpace < 300 && availableTopSpace > 300) {
    style.top = 'auto';
    style.bottom = considerHeight ? `${height}px` : '0px';
    style.maxHeight = `${availableTopSpace}px`;
  } else {
    style.maxHeight = `${availableBottomSpace}px`;
  }

  return style;
}

function Label(props) {
  const { text, description } = props;
  return (
    <>
      <p
        className={`suggestion-title ${description ? 'has-desc' : ''}`.trim()}
        title={text}
      >
        {text}
      </p>
      {description && <p title={description}>{description}</p>}
    </>
  );
}

function Input(props) {
  const {
    label,
    error = '',
    inputProps = {},
    className = '',
    showClearIcon = false,
    showSearchIcon = false,
    onChange = EMPTY_FUNCTION,
    searchClick = EMPTY_FUNCTION,
    suggestions = [],
    isSuggestionsLoading = false,
    selectMultiple = false,
    onSuggestionSelect = EMPTY_FUNCTION,
    clearValueOnOutsideClick = false,
    enableSuggestions = false,
    offsetValues,
    showSuggestionsByDefault = false,
  } = props;

  const id = useId();
  const {
    value,
    placeholder,
    type = 'text',
    disabled,
    onKeyPress,
    onBlur,
    onFocus,
    min,
    max,
    step,
    required,
    autoComplete,
  } = inputProps;

  const [showSuggestion, setShowSuggestion] = useState(false);

  const refCallback = useCallback((element) => {
    const inputElement = element?.previousSibling;
    if (inputElement) {
      const style = getSuggestionStyle(inputElement, offsetValues);

      Object.keys(style).forEach((key) => {
        // eslint-disable-next-line no-param-reassign
        element.style[key] = style[key];
      });
    }
  }, []);

  function handleClickOutside() {
    setShowSuggestion(false);
    if (clearValueOnOutsideClick) {
      onChange('');
    }
  }

  const ref = useOutsideClick(handleClickOutside);

  const onFocusCallback = (e) => {
    if (showSuggestionsByDefault) {
      setShowSuggestion(true);
    }
    if (onFocus !== undefined) {
      onFocus(e);
    }
  };

  return (
    <div className={`input ${className}`.trim()} ref={ref}>
      <label htmlFor={id}>{label}</label>
      {showSearchIcon && (
        <OpenSearchIcon className="search-icon" onClick={searchClick} />
      )}
      {showClearIcon && value && (
        <CloseIcon
          className="clear-icon"
          onClick={() => {
            onChange('');
          }}
        />
      )}
      <input
        value={value}
        disabled={disabled}
        id={id}
        placeholder={placeholder}
        type={type}
        className={error !== '' ? 'error-border' : ''}
        onChange={(e) => {
          const inputValue = e.target.value;
          onChange(inputValue);
          if (inputValue && enableSuggestions) {
            setShowSuggestion(true);
          }
        }}
        onKeyPress={onKeyPress}
        onBlur={onBlur}
        onFocus={onFocusCallback}
        min={min}
        max={max}
        step={step}
        required={required}
        autoComplete={autoComplete}
      />
      {showSuggestion && enableSuggestions && (
        <div className="suggestions-wrapper" ref={refCallback}>
          {isSuggestionsLoading ? (
            <div className="suggestion-item-wrapper">
              <InlineLoader />
            </div>
          ) : suggestions.length > 0 ? (
            suggestions.map((suggestion) => {
              const {
                text,
                description,
                checked = false,
                color = 'primary',
              } = suggestion;
              return (
                <div
                  className={`suggestion${selectMultiple ? ' multiple' : ''}`}
                  key={text}
                  onClick={() => {
                    if (!selectMultiple) {
                      onSuggestionSelect(suggestion, !checked);
                      setShowSuggestion(false);
                    }
                  }}
                >
                  {selectMultiple ? (
                    <ControlInput
                      color={color}
                      type="checkbox"
                      checked={checked}
                      label={<Label text={text} description={description} />}
                      onChange={(newChecked) => {
                        onSuggestionSelect(suggestion, newChecked);
                      }}
                    />
                  ) : (
                    <Label text={text} description={description} />
                  )}
                </div>
              );
            })
          ) : (
            <div className="suggestion-item-wrapper">No suggestions found</div>
          )}
        </div>
      )}
      {error !== '' && <div className="error">{error}</div>}
    </div>
  );
}

export default Input;
